import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import {Button, useMediaQuery} from "@mui/material";
import useAxios from "axios-hooks";
import SettingsService from "../../services/settings.service";
import {useTheme} from "@mui/system";

export default function CheckinAppBar({handleDialogOpen}) {
    const [{data: dataSettings, loading, error}] = useAxios(
        SettingsService.settingsUrl(), {method: "GET", useCache: false, manual: false}
    )

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'), {
        noSsr: true,
    });

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="fixed" color={'secondary'}>
                <Toolbar>
                    <Typography variant="body1" sx={{flexGrow: 1}}>
                        Check in <b>{dataSettings?.settings?.eventName || ''}</b>
                    </Typography>
                    {<Button
                        size="medium"
                        variant={'contained'}
                        aria-label="cerca iscritto"
                        color="accent"
                        onClick={handleDialogOpen}
                        endIcon={<PersonSearchIcon/>}
                        sx={smDown ? {position: 'fixed', right: 20, bottom: 20} : null}
                    >
                        Cerca partecipante
                    </Button>}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
